/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "animation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#animation",
    "aria-label": "animation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Animation"), "\n", React.createElement(_components.p, null, "Utilities for animating elements with CSS animations."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "animation={animation}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "animation: {animation};"))))), "\n", React.createElement(_components.h2, {
    id: "spin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#spin",
    "aria-label": "spin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Spin"), "\n", React.createElement(_components.p, null, "Add the ", React.createElement(_components.code, null, "animation=\"spin\""), " utility to add a linear spin animation to elements like loading indicators."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=rose"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"space-around\">\n      <x.span display=\"inline-flex\" borderRadius=\"md\" boxShadow=\"sm\">\n        <x.button\n          type=\"button\"\n          display=\"inline-flex\"\n          alignItems=\"center\"\n          px={4}\n          py={2}\n          border\n          borderColor=\"transparent\"\n          lineHeight={6}\n          fontWeight=\"medium\"\n          borderRadius=\"md\"\n          color=\"white\"\n          bg=\"rose-600\"\n          disabled\n        >\n          <x.svg\n            animation=\"spin\"\n            ml={-1}\n            mr={3}\n            h={5}\n            w={5}\n            color=\"white\"\n            fill=\"none\"\n            viewBox=\"0 0 24 24\"\n          >\n            <x.circle\n              opacity={0.25}\n              cx=\"12\"\n              cy=\"12\"\n              r=\"10\"\n              stroke=\"currentColor\"\n              strokeWidth=\"4\"\n            />\n            <x.path\n              opacity={0.75}\n              fill=\"currentColor\"\n              d=\"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z\"\n            />\n          </x.svg>\n          Processing\n        </x.button>\n      </x.span>\n    </x.div>\n  </template>\n  <x.button type=\"button\" bg=\"rose-600\" disabled>\n    <x.svg viewBox=\"0 0 24 24\" animation=\"spin\">\n      {/* ... */}\n    </x.svg>\n    Processing\n  </x.button>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "ping",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ping",
    "aria-label": "ping permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ping"), "\n", React.createElement(_components.p, null, "Add the ", React.createElement(_components.code, null, "animation=\"ping\""), " utility to make an element scale and fade like a radar ping or ripple of water — useful for things like notification badges."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=purple"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"space-around\">\n      <x.span display=\"inline-flex\" borderRadius=\"md\" boxShadow=\"sm\">\n        <x.button\n          type=\"button\"\n          display=\"inline-flex\"\n          alignItems=\"center\"\n          position=\"relative\"\n          px={4}\n          py={2}\n          border\n          borderColor={{ _: 'purple-400', focus: 'purple-300' }}\n          transition\n          lineHeight={6}\n          fontWeight=\"medium\"\n          borderRadius=\"md\"\n          color=\"purple-800\"\n          bg=\"white\"\n        >\n          <x.span\n            display=\"flex\"\n            w={3}\n            h={3}\n            position=\"absolute\"\n            top={0}\n            right={0}\n            mt={-1}\n            mr={-1}\n          >\n            <x.span\n              animation=\"ping\"\n              position=\"absolute\"\n              display=\"inline-flex\"\n              w={1}\n              h={1}\n              borderRadius=\"full\"\n              bg=\"purple-400\"\n              opacity={0.75}\n            />\n            <x.span\n              position=\"relative\"\n              display=\"inline-flex\"\n              borderRadius=\"full\"\n              h={3}\n              w={3}\n              bg=\"purple-500\"\n            />\n          </x.span>\n          Transactions\n        </x.button>\n      </x.span>\n    </x.div>\n  </template>\n  <x.span display=\"flex\" w={3} h={3}>\n    <x.span\n      animation=\"ping\"\n      position=\"absolute\"\n      display=\"inline-flex\"\n      w={1}\n      h={1}\n      borderRadius=\"full\"\n      bg=\"purple-400\"\n      opacity={0.75}\n    />\n    <x.span\n      position=\"relative\"\n      display=\"inline-flex\"\n      borderRadius=\"full\"\n      h={3}\n      w={3}\n      bg=\"purple-500\"\n    />\n  </x.span>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "pulse",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pulse",
    "aria-label": "pulse permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pulse"), "\n", React.createElement(_components.p, null, "Add the ", React.createElement(_components.code, null, "animation=\"pulse\""), " utility to make an element gently fade in and out — useful for things like skeleton loaders."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div\n      border\n      borderColor=\"light-blue-300\"\n      boxShadow\n      borderRadius=\"md\"\n      p={4}\n      maxWidth=\"sm\"\n      w={1}\n      mx=\"auto\"\n    >\n      <x.div animation=\"pulse\" display=\"flex\" spaceX={4}>\n        <x.div borderRadius=\"full\" bg=\"light-blue-400\" w={12} h={12} />\n        <x.div flex=\"1\" spaceY={4} py={1}>\n          <x.div h={4} bg=\"light-blue-400\" borderRadius w={3 / 4} />\n          <x.div spaceY={2}>\n            <x.div h={4} bg=\"light-blue-400\" borderRadius />\n            <x.div h={4} bg=\"light-blue-400\" borderRadius w={5 / 6} />\n          </x.div>\n        </x.div>\n      </x.div>\n    </x.div>\n  </template>\n  <x.div\n    border\n    borderColor=\"light-blue-300\"\n    boxShadow\n    borderRadius=\"md\"\n    p={4}\n    maxWidth=\"sm\"\n    w={1}\n    mx=\"auto\"\n  >\n    <x.div animation=\"pulse\" display=\"flex\" spaceX={4}>\n      <x.div borderRadius=\"full\" bg=\"light-blue-400\" w={12} h={12} />\n      <x.div flex=\"1\" spaceY={4} py={1}>\n        <x.div h={4} bg=\"light-blue-400\" borderRadius w={3 / 4} />\n        <x.div spaceY={2}>\n          <x.div h={4} bg=\"light-blue-400\" borderRadius />\n          <x.div h={4} bg=\"light-blue-400\" borderRadius w={5 / 6} />\n        </x.div>\n      </x.div>\n    </x.div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "bounce",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bounce",
    "aria-label": "bounce permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bounce"), "\n", React.createElement(_components.p, null, "Add the ", React.createElement(_components.code, null, "animation=\"bounce\""), " utility to make an element bounce up and down — useful for things like \"scroll down\" indicators."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"center\" my={5}>\n      <x.svg\n        animation=\"bounce\"\n        w={6}\n        h={6}\n        color=\"amber-800\"\n        fill=\"none\"\n        strokeLinecap=\"round\"\n        strokeLinejoin=\"round\"\n        strokeWidth=\"2\"\n        viewBox=\"0 0 24 24\"\n        stroke=\"currentColor\"\n      >\n        <path d=\"M19 14l-7 7m0 0l-7-7m7 7V3\"></path>\n      </x.svg>\n    </x.div>\n  </template>\n  <x.svg animation=\"bounce\" w={6} h={6}>\n    {/* ... */}\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "prefers-reduced-motion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prefers-reduced-motion",
    "aria-label": "prefers reduced motion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prefers-reduced-motion"), "\n", React.createElement(_components.p, null, "You can conditionally apply animations and transitions using the ", React.createElement(_components.code, null, "motionSafe"), " and ", React.createElement(_components.code, null, "motionReduce"), " states:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "<x.button type=\"button\" bg=\"indigo-600\" disabled>\n  <x.svg\n    animation={{ motionSafe: 'spin' }}\n    h={5}\n    w={5}\n    mr={3}\n    viewBox=\"0 0 24 24\"\n  >\n    {/* ... */}\n  </x.svg>\n  Processing\n</x.button>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To change or disable an animation at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "animation={{ md: \"spin\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "animation=\"spin\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div animation={{ md: 'spin' }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", React.createElement(_components.h3, {
    id: "animations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#animations",
    "aria-label": "animations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Animations"), "\n", React.createElement(_components.p, null, "Animations by their very nature tend to be highly project-specific. ", React.createElement(_components.strong, null, "The animations we include by default are best thought of as helpful examples"), ", and you're encouraged to customize your animations to better suit your needs."), "\n", React.createElement(_components.p, null, "By default xstyled provides utilities for four different example animations. You change, add, or remove these by customizing the animation section of your theme configuration."), "\n", React.createElement(_components.p, null, "Note that ", React.createElement(_components.strong, null, "animations ", React.createElement(_components.code, null, "keyframes"), " have to be declared globally"), ". Default animations provided by xstyled are declared in ", React.createElement(_components.a, {
    href: "/docs/preflight/"
  }, "preflight"), " and are prefixed by ", React.createElement(_components.code, null, "x-"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diffjs"
  }, "  // theme.js\n\n  export const theme = {\n    animations: {\n      // ...\n     'spin-slow': 'x-spin 3s linear infinite',\n    },\n  }\n")), "\n", React.createElement(_components.p, null, "If you don't want to customize it, a set of ", React.createElement(_components.code, null, "animations"), " is already defined in default theme:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const defaultTheme = {\n  // ...\n  animations: {\n    spin: 'x-spin 1s linear infinite',\n    ping: 'x-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',\n    pulse: 'x-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',\n    bounce: 'x-bounce 1s infinite',\n  },\n}\n")), "\n", React.createElement(_components.h2, {
    id: "styled-bindings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#styled-bindings",
    "aria-label": "styled bindings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Styled bindings"), "\n", React.createElement(_components.h3, {
    id: "automatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automatic",
    "aria-label": "automatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automatic"), "\n", React.createElement(_components.p, null, "Using xstyled's ", React.createElement(_components.code, null, "styled"), ", all animations defined are automatically bound to ", React.createElement(_components.code, null, "animation"), " attribute:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '@xstyled/...'\n\nconst Button = styled.button`\n  animation: ping;\n`\n")), "\n", React.createElement(_components.p, null, "To learn more about styled syntax, read ", React.createElement(_components.a, {
    href: "/docs/magic-styled-components/"
  }, "styled syntax documentation"), "."), "\n", React.createElement(_components.h3, {
    id: "manual",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manual",
    "aria-label": "manual permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manual"), "\n", React.createElement(_components.p, null, "It is possible to manually bind a animation property using ", React.createElement(_components.code, null, "th.animation"), " utility:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '...'\nimport { th } from '@xstyled/...'\n\nconst Button = styled.button`\n  animation: ${th.animation('ping')};\n`\n")), "\n", React.createElement(_components.h2, {
    id: "hooks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hooks",
    "aria-label": "hooks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hooks"), "\n", React.createElement(_components.p, null, "Get a animation property in any component using ", React.createElement(_components.code, null, "useAnimation"), " hook:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { useAnimation } from '@xstyled/...'\n\nfunction Button() {\n  const animation = useAnimation('ping')\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
